import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "src/components/Layout"
import PageHero from "src/components/PageHero"
import QuoteBlock from "src/components/QuoteBlock"
import LatestInsights from "src/components/insights/Latest"
import Content from "src/components/Content"

import "./aboutTemplate.scss"

const AboutTemplate = ({data: {page: {frontmatter: {
  meta, hero, team, why
}}, quote, footerCards}}) => (
  <Layout meta={meta}>
    <div className="about-template">
      <PageHero
        title={hero.title}
        subtitle={hero.subtitle}
        heroTriangles
        overlay={false}
      />
      <section className="about-detail">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <Content html={team.description} />
            </div>
          </div>
        </div>
      </section>

      <section className="about-team" id="team">
        <h2 className="h1">Our Leadership</h2>
        <div className="container leadership">
          {team.members.map(member => (
            <div
                  // data-sal="slide-up"
                  // data-sal-duration="1000"
                  // data-sal-easing="cubic-bezier(0.5, 0, 0, 1)"
                  key={member.name}
                  className="row member"
                > 
              <div className="col-md-3">
                <GatsbyImage image={member.image.childImageSharp.gatsbyImageData} alt={member.name} />
              </div>
              <div className="col-md-9">
                <div className="member-detail">
                  <h3 className="member-name">{member.name}</h3>
                  <h5 className="member-title">{member.title}</h5>
                  <p className="member-bio">{member.bio}</p>
                  <span className="member-links">
                    {member.links && Object.keys(member.links).map(link => {
                      if(!member.links[link]) return ""
                      return (
                        <a 
                          key={link}
                          href={member.links[link]} 
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={`/img/team-${link}.svg`} alt={link} />
                        </a>
                      )
                    })}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="about-why">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="h1">{why.title}</h2>
              <Content html={why.text} />
            </div>
          </div>
        </div>
      </section>

      <section className="about-join">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="h1">Join Us</h2>
              <p>
                Check out our{" "}
                <a 
                  href="https://www.vaporware.net/careers"
                  target="_blank"
                  rel="noopener noreferrer">
                  current job openings
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="about-quote">
        <QuoteBlock {...quote.frontmatter} />
      </section>

      <section className="about-resources">
        <div className="container">
          <LatestInsights />
        </div>
      </section>

    </div>
  </Layout>
)

export default AboutTemplate

export const pageQuery = graphql`query ($path: String!, $quote: String!) {
  page: markdownRemark(frontmatter: {meta: {path: {eq: $path}}}) {
    html
    ...MetaFragment
    ...HeroFragment
    frontmatter {
      team {
        description
        members {
          name
          image {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
          title
          bio
          links {
            twitter
            github
            dribble
            linkedin
          }
        }
      }
      why {
        title
        text
      }
    }
  }
  ...quoteQuery
}
`
