import React from "react"
import { graphql, StaticQuery } from "gatsby"

import InsightCarousel from "./Carousel"

const LatestInsights = () => (
  <StaticQuery
    query={graphql`
      query LatestInsights {
        latestInsights: allMarkdownRemark(
          filter: { frontmatter: { meta: { template: { in: ["post", "letter", "media"] }, published: { eq: true }, indexed: { ne: false } } } }
          sort: { fields: frontmatter___meta___date, order: DESC }
          limit: 6
        ) {
          cards: edges {
            node {
              ...InsightFragment
            }
          }
        }
      }
    `}

    render={data => {
      const { cards } = data.latestInsights

      return (
        <>
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <h2 className="h1 text-center">Latest Insights</h2>
            </div>
          </div>
          <InsightCarousel cards={cards} />
        </>
      )

    }}
  />
)

LatestInsights.defaultProps = {
}

LatestInsights.propTypes = {
}

export default LatestInsights
